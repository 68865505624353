/* You can add global styles to this file, and also import other style files */
@import '../../../node_modules/primeng/resources/themes/nova/theme.css';
@import '../../../node_modules/primeng/resources/primeng.min.css';
@import '../../../node_modules/simple-keyboard/build/css/index.css';

// Common Css

/* You can add global styles to this file, and also import other style files */
// Start common css
html,
body {
  word-wrap: break-word;
  outline: none;
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea {
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dd,
dt,
dl {
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

a img,
img {
  border: none;
  outline: none;
}
img {
  width: auto;
  max-width: 100%;
  display: block;
}

input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

a,
button {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
  color: inherit;
  transition: 0.3s;
  &:hover {
    text-decoration: none;
  }
}

input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  background-color: #fff;
  // overflow-x: hidden;
}

::selection {
  text-shadow: none;
  background: rgba(65, 131, 196, 0.4);
}

.clear {
  clear: both;
  height: 0px;
  overflow: hidden;
  width: auto;
  display: block;
  float: none !important;
}

ul,
menu,
dir {
  margin: 0px;
  padding: 0px;
  display: block;
  list-style-type: none;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
}

*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
}

@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-BoldItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-BoldItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-BoldItalic.svg#SharpSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-ExtraboldItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-ExtraboldItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-ExtraboldItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-ExtraboldItalic.svg#SharpSans-ExtraboldItalic') format('svg');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-Bold.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Bold.woff') format('woff'),
    url('/assets/fonts/SharpSans-Bold.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-Bold.svg#SharpSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-Extrabold.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Extrabold.woff') format('woff'),
    url('/assets/fonts/SharpSans-Extrabold.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-Extrabold.svg#SharpSans-Extrabold') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-BookItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-BookItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-BookItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-BookItalic.svg#SharpSans-BookItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-LightItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-LightItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-LightItalic.svg#SharpSans-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-Light.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Light.woff') format('woff'),
    url('/assets/fonts/SharpSans-Light.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-Light.svg#SharpSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-MediumItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-MediumItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-MediumItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-MediumItalic.svg#SharpSans-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-Medium.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Medium.woff') format('woff'),
    url('/assets/fonts/SharpSans-Medium.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-Medium.svg#SharpSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-SemiboldItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-SemiboldItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-SemiboldItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-SemiboldItalic.svg#SharpSans-SemiboldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-SemiBold.woff') format('woff'),
    url('/assets/fonts/SharpSans-SemiBold.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-SemiBold.svg#SharpSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-ThinItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-ThinItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-ThinItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-ThinItalic.svg#SharpSans-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-Thin.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Thin.woff') format('woff'),
    url('/assets/fonts/SharpSans-Thin.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-Thin.svg#SharpSans-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-Book.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Book.woff') format('woff'),
    url('/assets/fonts/SharpSans-Book.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-Book.svg#SharpSans-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-weight: normal;
  * {
    font-family: 'Sharp Sans';
  }
}

.p-button.theme-button {
  color: #fff;
  background-color: #e85a90;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  align-items: center;
  text-align: center;
  border-radius: 0px;
  padding: 10px 20px 5px;
  border: 1px solid #e85a90;
}
.p-button.theme-button:enabled:hover {
  color: #fff;
  background-color: #e85a90;
  border: 1px solid #e85a90;
}
body .p-dialog {
  border: none;
}
body .p-dialog .p-dialog-header {
  background: #0481c4;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-bottom: none;
}
body .p-dialog .p-dialog-header .p-dialog-header-icon,
body .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #fff;
}

.simple-keyboard.hg-theme-default {
  //   width: 57%;
  //   max-width: 523px;
  //   position: absolute;
  top: calc(100% + 10px) !important;
  position: absolute;
  right: 0;
  z-index: 10000;
  padding: 13px 14px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
  transform: translateY(-30px);
  max-height: 0px;
  opacity: 0;
  width: min(68%, calc(100% - 410px));

  .hg-rows {
    display: grid;
    gap: 5px;
    .hg-row {
      gap: 5px;
      margin: 0;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      .hg-button {
        color: #fff;
        font-size: 1.5vw;
        font-weight: 500;
        border-radius: 60px;
        border: 0;
        background-color: #262626;
        box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
        position: relative;
        padding: 0;
        height: unset;
        padding-top: 100%;
        width: 100%;
        &.space-btn {
          grid-column-start: 1;
          grid-column-end: -1;
          padding-top: calc((100% - 5px) / 12);
        }

        &.long-btn {
          min-width: 85px;
          grid-column-end: span 2;
          padding-top: calc((100% - 5px) / 2);
        }

        &.clear-btn {
          font-size: calc(100% - 2px);
        }

        &:focus,
        &.hg-activeButton {
          background-color: #0481c4;
          color: #ffffff;
        }

        span {
          position: absolute;
          display: block;
          top: 50%;
          margin: auto;
          transform: translateY(-50%);
        }
      }
    }
  }
}

#init-spinner .api-ref-block {
  display: none;
}
#init-spinner.timeout-occurred .api-ref-block {
  display: flex;
}

[hidden] {
  display: none !important;
}
